import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const useTimer = () => {
  const [active, setActive] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>();
  const tick = useRef<number>();

  const startTimer = (endsAt: string, interval: number = 1000) => {
    const when = moment.utc(endsAt).subtract(interval, 'milliseconds');
    let diff = when.diff(moment.utc(), 'ms');
    setRemaining(diff);
    setActive(true);

    tick.current = window.setInterval(() => {
      diff = when.diff(moment.utc(), 'ms');
      setRemaining(diff);

      if (diff <= 0) {
        clearTimer();
      }
    }, interval);
  };

  const clearTimer = () => {
    setActive(false);
    setRemaining(0);
    clearInterval(tick.current);
  };

  useEffect(() => {
    return () => clearTimer();
  }, []);

  return {
    startTimer,
    clearTimer,
    remaining,
    active,
  };
};

export default useTimer;