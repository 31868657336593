import { useEffect, useState } from 'react';

const ScriptLocation = ['head', 'body',] as const;
const ScriptLoadingStatus = ['idle', 'loading', 'ready', 'error',] as const;

const useScript = (src: string, location: typeof ScriptLocation[number] = 'head') => {
  const [status, setStatus] = useState<typeof ScriptLoadingStatus[number]>(src ? 'loading' : 'idle');

  useEffect(() => {
      if (!src) {
        setStatus('idle');

        return;
      }

      let script = document.querySelector(`script[src="${src}"]`);
      if (script === null) {
        script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', src);
        script.setAttribute('async', 'true');
        script.setAttribute('data-status', 'loading');

        document[location].appendChild(script);

        const setAttributeFromEvent = (event: Event) => {
          script!.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        setStatus(script!.getAttribute('data-status') as typeof ScriptLoadingStatus[number]);
      }

      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      return () => {
        script!.removeEventListener('load', setStateFromEvent);
        script!.removeEventListener('error', setStateFromEvent);
      };
    },
    [src]
  );
  return status;
};

export default useScript;