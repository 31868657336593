import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Link, Modal, Typography, useTheme } from '@mui/material';
import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/system';
import axios, { AxiosError } from 'axios';
import { CardNumber, Cvv, ExpiryDate, FrameCardTokenizationFailedEvent, FrameCardTokenizedEvent, Frames } from 'frames-react';
import { navigate } from 'gatsby';
import { template } from 'lodash';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { Header, StepContainer } from '../components/SignUpSteps/components';
import useScript from '../helpers/use-script';
import useTimer from '../helpers/use-timer';
import logError from '../helpers/log-error';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const GATSBY_PAYMENT_CHECKOUT_STATIC_KEY = yup.string().required().validateSync(process.env.GATSBY_PAYMENT_CHECKOUT_STATIC_KEY);
const GATSBY_PAYMENT_DEBUG = yup.boolean().required().validateSync(process.env.GATSBY_PAYMENT_DEBUG);
const GATSBY_API_URL = yup.string().required().validateSync(process.env.GATSBY_API_URL);
const GATSBY_PAYMENT_REQUEST_TIMEOUT_SECONDS = yup
  .number()
  .integer()
  .min(1)
  .required()
  .validateSync(process.env.GATSBY_PAYMENT_REQUEST_TIMEOUT_SECONDS);
const GATSBY_PAYMENT_SESSION_TIMEOUT_MINUTES = yup
  .number()
  .integer()
  .min(1)
  .required()
  .validateSync(process.env.GATSBY_PAYMENT_SESSION_TIMEOUT_MINUTES);
const SUPPORT_URL = 'https://presspay.zendesk.com/hc/en-au/categories/4638173508889-Payments';
const GATSBY_PAYMENT_MINIMUM_AMOUNT = yup.number().integer().min(1).required().validateSync(process.env.GATSBY_PAYMENT_MINIMUM_AMOUNT);
const GENERIC_ERROR_HEADER = 'Oh No';

const apiClient = axios.create({ baseURL: GATSBY_API_URL });

interface PaymentError {
  code: string;
  message: string;
}

const PaymentErrors = {
  INVALID_PAYMENT_TOKEN: {
    code: 'INVALID_PAYMENT_TOKEN',
    message: template('Invalid payment token.'),
  },
  SESSION_EXPIRED: {
    code: 'SESSION_EXPIRED',
    message: template('Your session has expired.'),
  },
  SUBMIT_EXPIRED: {
    code: 'SUBMIT_EXPIRED',
    message: template('Unfortunately a timeout occurred. Please try your payment again.'),
  },
  DEBIT_CARDS_ONLY: {
    code: 'DEBIT_CARDS_ONLY',
    message: template('We only accept payments by debit card. <br/><br/>Please select a valid debit card and try again.'),
  },
  UNSUPPORTED_CARD_TYPE: {
    code: 'UNSUPPORTED_CARD_TYPE',
    message: template(
      'We currently accept debit cards issued by Mastercard and Visa.<br/><br/>Please select a valid debit card and try again.',
    ),
  },
  INSUFFICIENT_MINIMUM: {
    code: 'INSUFFICIENT_MINIMUM',
    message: template('A minimum payment of ${minimum} is required for debit cards. Your balance due is ${balance}.'),
  },
  NO_BALANCE_DUE: {
    code: 'NO_BALANCE_DUE',
    message: template('Your current balance due is $0.00. You have nothing to pay for at the moment.'),
  },
  PAYMENT_LINK_EXPIRED: {
    code: 'PAYMENT_LINK_EXPIRED',
    message: template('The payment link has expired.'),
  },
  PAYMENT_LINK_INVALID: {
    code: 'PAYMENT_LINK_INVALID',
    message: template('The payment link is not valid.'),
  },
  UNHANDLED_ERROR: {
    code: 'UNHANDLED_ERROR',
    message: template(
      'An unexpected error occurred. <br/><br/>Please try again, or contact <a href="${SUPPORT_URL}">support</a> for further assistance if needed.',
    ),
  },
  TOKENIZATION_ERROR: {
    code: 'TOKENIZATION_ERROR',
    message: template('An unexpected error occurred <br/><br/>Please try again'),
  },
};

enum SubmitState {
  Disabled,
  CanSubmit,
  Submitting,
  Error,
  Success,
}

interface Balance {
  balancePayable: number;
}

interface BalanceResponse {
  id: string;
  attributes: Balance;
}

const TransactionStatus = ['cancelled', 'confirmed', 'failed', 'voided'] as const;

interface Transaction {
  id: string;
  status: (typeof TransactionStatus)[number];
  statusReason: string;
}

interface PaymentResponse {
  id: string;
  attributes: Transaction;
}

const paymentDetailsSchema = yup.object({
  sessionToken: yup.string().uuid().required(),
  amount: yup.number().min(GATSBY_PAYMENT_MINIMUM_AMOUNT).max(1000).required(),
  token: yup.string().required(),
  expiryMonth: yup.number().integer().min(1).max(12).required(),
  expiryYear: yup.number().integer().min(new Date().getFullYear()).required(),
  firstSixDigits: yup
    .string()
    .matches(/^\d{6}$/)
    .required(),
  lastFourDigits: yup
    .string()
    .matches(/^\d{4}$/)
    .required(),
});

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

const FormActions = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PayButton = styled(Button)(() => ({
  padding: '.75rem 1.5rem',
}));

const Hero = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(30),
}));

const PopoverBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.pressPayGreen.main,
  '& > svg': {
    marginRight: theme.spacing(1),
  },
}));

const Terms = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginTop: `${theme.spacing(3)}`,
  marginBottom: `${theme.spacing(3)}`,
  // @ts-ignore
  fontSize: theme.typography.caption.fontSize,
}));

interface TimerProps {
  remaining: number | undefined;
}

const Timer = ({ remaining }: TimerProps) => (
  <Typography marginBottom={2} sx={{ fontSize: '.9rem', textAlign: 'center', color: '#707070' }}>
    {remaining ? (remaining > 0 ? `(${moment.duration(remaining).humanize()} remaining)` : '(Session Expired)') : ''}
  </Typography>
);

const ModalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '4px',
  width: '80vw',
  [theme.breakpoints.up('md')]: {
    width: '60vw',
  },
  [theme.breakpoints.up('lg')]: {
    width: '40vw',
  },
}));

interface ModalContent {
  heading: string;
  message: string;
  code?: string;
  icon?: boolean;
  isCloseHidden?: boolean;
}

const ModalBoxCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
}));

const ModalBoxHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  borderBottom: '1px solid',
  borderBottomColor: grey[300],
  width: '100%',
}));

const ModalBoxContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const ModalBoxFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  width: '100%',
  backgroundColor: theme.palette.pressPayGreen.main,
  fontSize: '.7rem',
}));

const LoaderWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '375px',
}));

const FormWrapper = styled(Box)(() => ({
  opacity: 0,
  '&.show': {
    opacity: 1,
    transition: 'opacity .5s',
  },
}));

const Loader = () => (
  <LoaderWrapper>
    <CircularProgress color="secondary" />
  </LoaderWrapper>
);

const Page = () => {
  const theme = useTheme();
  const [error, setError] = useState<PaymentError | null>(null);
  const [mounted, setMounted] = useState<boolean>(false);
  const [popoverContent, setPopoverContent] = useState<string | undefined>('');
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<SVGSVGElement | null>(null);
  const { remaining, startTimer, clearTimer, active } = useTimer();
  const [submitState, setSubmitState] = useState<SubmitState>(SubmitState.Disabled);
  const sessionDetails = useRef<{
    sessionToken: string;
    amount: number;
  }>();
  const [modalContent, setModalContent] = useState<ModalContent | undefined>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const paymentFormRef = useRef<HTMLFormElement>(null);
  const framesScriptLoadingStatus = useScript('https://cdn.checkout.com/js/framesv2.min.js');
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const agreedToTermsRef = useRef<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getPaymentDetails = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tokenSchema = yup.string().uuid().required();
    const sessionToken = urlSearchParams.get('token') as string;

    if (!(await tokenSchema.isValid(sessionToken))) {
      setError({
        code: PaymentErrors.INVALID_PAYMENT_TOKEN.code,
        message: PaymentErrors.INVALID_PAYMENT_TOKEN.message(),
      });
      clearTimer();
      setMounted(true);

      return;
    }

    try {
      const apiClient = axios.create({ baseURL: GATSBY_API_URL });
      const { data } = await apiClient.get<{
        data: BalanceResponse;
      }>(`/transactions/debit-cards/session/${sessionToken}`);

      const {
        data: { attributes: balance },
      } = data;

      if (balance.balancePayable === 0) {
        setError({
          code: PaymentErrors.NO_BALANCE_DUE.code,
          message: PaymentErrors.NO_BALANCE_DUE.message(),
        });
        clearTimer();
        setMounted(true);
        return;
      }

      if (balance.balancePayable < GATSBY_PAYMENT_MINIMUM_AMOUNT) {
        const message = PaymentErrors.INSUFFICIENT_MINIMUM.message({
          balance: balance.balancePayable.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
          }),
          minimum: GATSBY_PAYMENT_MINIMUM_AMOUNT.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
          }),
        });

        setError({
          code: PaymentErrors.INSUFFICIENT_MINIMUM.code,
          message,
        });
        clearTimer();
        setMounted(true);
        return;
      }

      sessionDetails.current = {
        sessionToken,
        amount: balance.balancePayable,
      };

      startTimer(moment.utc().add(GATSBY_PAYMENT_SESSION_TIMEOUT_MINUTES, 'minutes').toISOString());
      setMounted(true);
    } catch (err: unknown | AxiosError) {
      if (axios.isAxiosError(err)) {
        switch (err.response!.status) {
          case 500:
            setError({
              code: PaymentErrors.UNHANDLED_ERROR.code,
              message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }),
            });
            break;
          case 404:
            setError({
              code: PaymentErrors.PAYMENT_LINK_EXPIRED.code,
              message: PaymentErrors.PAYMENT_LINK_EXPIRED.message(),
            });
            break;
          case 400:
            setError({
              code: PaymentErrors.PAYMENT_LINK_INVALID.code,
              message: PaymentErrors.PAYMENT_LINK_INVALID.message({ SUPPORT_URL }),
            });
            break;
        }
      } else {
        setError({
          code: PaymentErrors.UNHANDLED_ERROR.code,
          message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }),
        });
      }
      setMounted(true);
      clearTimer();
    }
  };

  const submitPayment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const controller = new AbortController();

    clearTimer();
    setSubmitState(SubmitState.Submitting);
    showModal({
      heading: 'Processing Payment',
      message: 'One moment while we process your payment.',
      icon: true,
      isCloseHidden: true,
      code: 'processing',
    });

    try {
      // we wrap below as the post within Frames will not throw and bubble
      const handle = window.setTimeout(() => {
        setSubmitState(SubmitState.Error);
        window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
        showModal({
          heading: GENERIC_ERROR_HEADER,
          message: PaymentErrors.TOKENIZATION_ERROR.message(),
          code: PaymentErrors.TOKENIZATION_ERROR.code,
        });
        Frames.enableSubmitForm();
      }, 15000);
      const frameCardTokenizedEvent: FrameCardTokenizedEvent = await Frames.submitCard();
      window.clearTimeout(handle);

      if (typeof frameCardTokenizedEvent.card_type === 'undefined') {
        setSubmitState(SubmitState.Error);
        window.setTimeout(() => setSubmitState(SubmitState.Disabled), 2000);
        showModal({
          heading: 'Invalid Card Type',
          message: PaymentErrors.UNSUPPORTED_CARD_TYPE.message(),
          code: PaymentErrors.UNSUPPORTED_CARD_TYPE.code,
        });
        Frames.init();
        return;
      } else if (frameCardTokenizedEvent.card_type.toUpperCase() !== 'DEBIT') {
        setSubmitState(SubmitState.Error);
        window.setTimeout(() => setSubmitState(SubmitState.Disabled), 2000);
        showModal({
          heading: 'Invalid Card Type',
          message: PaymentErrors.DEBIT_CARDS_ONLY.message(),
          code: PaymentErrors.DEBIT_CARDS_ONLY.code,
        });
        Frames.init();
        return;
      }

      const attributes = paymentDetailsSchema.validateSync({
        sessionToken: sessionDetails.current!.sessionToken,
        amount: sessionDetails.current!.amount,
        token: frameCardTokenizedEvent.token,
        expiryMonth: Number.parseInt(frameCardTokenizedEvent.expiry_month),
        expiryYear: Number.parseInt(frameCardTokenizedEvent.expiry_year),
        lastFourDigits: frameCardTokenizedEvent.last4,
        firstSixDigits: frameCardTokenizedEvent.bin,
      });

      const { data } = await apiClient.post<{
        data: PaymentResponse;
      }>(
        `/transactions/debit-cards`,
        { data: { attributes } },
        {
          signal: controller.signal,
          timeout: GATSBY_PAYMENT_REQUEST_TIMEOUT_SECONDS * 1000,
        },
      );

      const {
        data: { attributes: transaction, id },
      } = data;
      switch (transaction.status) {
        case 'confirmed':
          setSubmitState(SubmitState.Success);
          navigate(`/payment-result/?token=${attributes.sessionToken}&status=${transaction.status}&id=${id}`);
          break;
        case 'failed':
        case 'cancelled':
        default:
          showModal({
            heading: 'Payment Failed',
            message: 'The payment could not be completed.',
            code: transaction.statusReason,
          });
          setSubmitState(SubmitState.Error);
          window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
          Frames.enableSubmitForm();
          break;
      }
    } catch (err: unknown | AxiosError) {
      if (axios.isAxiosError(err)) {
        if (err.code === 'ECONNABORTED') {
          console.error(err.code, err.message);
          setSubmitState(SubmitState.Error);
          window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
          showModal({
            heading: GENERIC_ERROR_HEADER,
            code: PaymentErrors.SUBMIT_EXPIRED.code,
            message: PaymentErrors.SUBMIT_EXPIRED.message(),
          });
          Frames.enableSubmitForm();
        } else if (err.response) {
          switch (err.response.status) {
            case 500:
              const { errors } = err.response.data as unknown as {
                errors: [
                  {
                    code: string;
                    title: string;
                  },
                ];
              };
              if (errors && errors.length > 0) {
                const error = errors[0];
                if (error.code === 'TransactionCommitError') {
                  return navigate(`/payment-result/?token=${sessionDetails.current!.sessionToken}&status=timeout`);
                } else {
                  showModal({
                    heading: GENERIC_ERROR_HEADER,
                    message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }),
                    code: error.code,
                  });
                  setSubmitState(SubmitState.Error);
                  window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
                  Frames.enableSubmitForm();
                  return;
                }
              }
              showModal({
                heading: GENERIC_ERROR_HEADER,
                message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }),
              });
              setSubmitState(SubmitState.Error);
              window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
              Frames.enableSubmitForm();
              break;
            case 404:
              return navigate(`/payment-result/?token=${sessionDetails.current!.sessionToken}&status=expired`);
            case 400:
              return navigate(`/payment-result/?token=${sessionDetails.current!.sessionToken}&status=invalid`);
            default:
              showModal({
                heading: GENERIC_ERROR_HEADER,
                message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }),
              });
              window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
              Frames.enableSubmitForm();
              break;
          }
        }
      } else {
        setSubmitState(SubmitState.Error);
        window.setTimeout(() => setSubmitState(SubmitState.CanSubmit), 2000);
        showModal({ heading: GENERIC_ERROR_HEADER, message: PaymentErrors.UNHANDLED_ERROR.message({ SUPPORT_URL }) });
        Frames.enableSubmitForm();
      }
    }
  };

  const validate = async () => {
    if (agreedToTermsRef.current === true && Frames.isCardValid()) {
      setSubmitState(SubmitState.CanSubmit);
    } else {
      setSubmitState(SubmitState.Disabled);
    }
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAgreedToTerms(checked);
    // unfortunately, needed due to Frames scoping
    agreedToTermsRef.current = checked;
    void validate();
  };

  const showModal = (content: ModalContent) => {
    setModalContent(content);
    setOpenModal(true);
  };

  // @ts-ignore
  const closeModal = (e: unknown, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    setModalContent({ heading: '', message: '' });
    setOpenModal(false);
  };

  const handlePopoverOpen = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setPopoverContent(e.currentTarget.dataset.popoverContent);
    setPopoverAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverContent('');
    setPopoverAnchorEl(null);
  };

  useEffect(() => {
    if (typeof remaining !== 'undefined' && active && remaining <= 0) {
      console.info(active, remaining);
      showModal({
        heading: 'Session Expired',
        message: PaymentErrors.SESSION_EXPIRED.message(),
        code: PaymentErrors.SESSION_EXPIRED.code,
      });
      (async () => {
        await getPaymentDetails();
      })();
    }
  }, [remaining]);

  return (
    <>
      <Helmet>
        <title>Pay - PressPay</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StepContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
          <Header>Debit Card Payment</Header>
        </Box>
        {framesScriptLoadingStatus !== 'ready' ? (
          <Loader />
        ) : (
          <>
            {!mounted && <Loader />}
            {mounted && error !== null && (
              <Box
                sx={{
                  textAlign: 'center',
                }}
                dangerouslySetInnerHTML={{
                  __html: error.message,
                }}
              />
            )}

            <FormWrapper
              sx={{ overflow: 'hidden', height: mounted && !error ? 'auto' : 0 }}
              className={`${mounted && !error ? 'show' : 'hide'}`}
            >
              <Typography sx={{ mb: 2, textAlign: 'center' }}>Repay your balance due with a valid debit card.</Typography>
              <Form ref={paymentFormRef} onSubmit={submitPayment}>
                <Timer remaining={remaining} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                      <PaidIcon
                        sx={{ color: grey[600], marginRight: '.5rem' }}
                        data-popover-content="The amount to be paid"
                        onClick={handlePopoverOpen}
                        aria-haspopup="true"
                      />
                      <Box
                        sx={{
                          paddingLeft: '1rem',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          borderBottom: `1px solid ${grey[400]};`,
                        }}
                      >
                        {sessionDetails.current &&
                          sessionDetails.current.amount.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                          })}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                      <CreditCardIcon
                        sx={{ color: grey[600], marginRight: '.5rem' }}
                        data-popover-content="A valid debit card number"
                        onClick={handlePopoverOpen}
                        aria-haspopup="true"
                      />
                      <Box sx={{ height: '50px', width: '100%' }}>
                        <div className="card-number-frame" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                      <CalendarTodayOutlinedIcon
                        sx={{ color: grey[600], marginRight: '.5rem' }}
                        data-popover-content="Card expiration date: Month/Year"
                        onClick={handlePopoverOpen}
                        aria-haspopup="true"
                      />
                      <Box sx={{ height: '50px', width: '100%' }}>
                        <div className="expiry-date-frame" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                      <LockOutlinedIcon
                        sx={{ color: grey[600], marginRight: '.5rem' }}
                        data-popover-content="Card cvv code"
                        onClick={handlePopoverOpen}
                        aria-haspopup="true"
                      />
                      <Box sx={{ height: '50px', width: '100%' }}>
                        <div className="cvv-frame" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Terms>
                  <FormControlLabel
                    control={<Checkbox color="default" checked={agreedToTerms} onChange={handleTermsChange} />}
                    label={
                      <Typography variant="body2" align="left">
                        I have read, understood and agree to the&nbsp;
                        <Link
                          color="secondary"
                          target="_blank"
                          rel="noreferrer"
                          href="https://presspay.com.au/debit-card-authority-202309/"
                        >
                          Debit Card Authority
                        </Link>
                        .
                      </Typography>
                    }
                  />
                </Terms>

                <FormActions>
                  <PayButton size="large" variant="contained" type="submit" disabled={submitState !== SubmitState.CanSubmit}>
                    {
                      {
                        [SubmitState.CanSubmit]: `PAY ${
                          sessionDetails.current &&
                          sessionDetails.current.amount.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                          })
                        }`,
                        [SubmitState.Submitting]: 'Submitting...',
                        [SubmitState.Disabled]: `PAY ${
                          sessionDetails.current &&
                          sessionDetails.current.amount.toLocaleString('en-AU', {
                            style: 'currency',
                            currency: 'AUD',
                          })
                        }`,
                        [SubmitState.Error]: 'Oops',
                        [SubmitState.Success]: 'Success',
                      }[submitState]
                    }
                  </PayButton>
                </FormActions>
              </Form>
            </FormWrapper>

            <Frames
              config={{
                debug: GATSBY_PAYMENT_DEBUG,
                publicKey: GATSBY_PAYMENT_CHECKOUT_STATIC_KEY,
                localization: {
                  cardNumberPlaceholder: 'Debit Card Number',
                  expiryMonthPlaceholder: 'MM',
                  expiryYearPlaceholder: 'YY',
                  cvvPlaceholder: 'CVV',
                },
                style: {
                  base: {
                    fontSize: '1rem',
                    fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
                    fontWeight: 400,
                    border: '1px solid',
                    borderColor: grey[400],
                    borderRadius: '4px',
                    padding: '1rem',
                    marginBottom: '1rem',
                    height: 'auto',
                    letterSpacing: '.1rem',
                    color: theme.palette.text.primary,
                  },
                  focus: {
                    borderColor: theme.palette.secondary.main,
                    padding: 'calc(1rem - 1px)',
                    borderWidth: '2px',
                  },
                  invalid: {
                    backgroundColor: theme.palette.error.light,
                    borderColor: theme.palette.error.main,
                    padding: 'calc(1rem - 1px)',
                    borderWidth: '2px',
                  },
                  placeholder: {
                    base: {
                      color: theme.palette.text.secondary,
                    },
                  },
                },
              }}
              ready={async () => {
                await getPaymentDetails();
              }}
              cardValidationChanged={() => {
                void validate();
              }}
              cardTokenized={() => {}}
              cardTokenizationFailed={async (e: FrameCardTokenizationFailedEvent) => {
                showModal({
                  heading: GENERIC_ERROR_HEADER,
                  message: PaymentErrors.TOKENIZATION_ERROR.message(),
                  code: PaymentErrors.TOKENIZATION_ERROR.code,
                });

                if (!executeRecaptcha) {
                  return;
                }

                const token = await executeRecaptcha('e');
                await logError({
                  level: 'error',
                  code: PaymentErrors.TOKENIZATION_ERROR.code,
                  message: e.message,
                  sessionToken: sessionDetails.current!.sessionToken,
                  t: token,
                });
              }}
            >
              <CardNumber />
              <ExpiryDate />
              <Cvv />
            </Frames>
          </>
        )}
      </StepContainer>
      <Modal
        disableAutoFocus={true}
        disableEscapeKeyDown={true}
        open={openModal}
        onClose={(e: unknown, reason: string) => {
          closeModal(e, reason);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          {modalContent && !modalContent.isCloseHidden && (
            <ModalBoxCloseButton aria-label="close" onClick={(e) => closeModal(e)}>
              <CloseOutlinedIcon />
            </ModalBoxCloseButton>
          )}
          <ModalBoxHeader>
            <Typography id="modal-modal-title" variant="h2" component="h2">
              {modalContent && modalContent.heading}
            </Typography>
          </ModalBoxHeader>
          <ModalBoxContent>
            {modalContent && modalContent.icon && (
              <Box sx={{ width: '150px', height: '150px', mb: 2 }} component="img" src="/assets/processing.png" alt="processing..." />
            )}
            {modalContent && modalContent.message && (
              <Typography
                align="center"
                id="modal-modal-description"
                dangerouslySetInnerHTML={{
                  __html: modalContent && modalContent.message,
                }}
              />
            )}
          </ModalBoxContent>
          {modalContent && modalContent.code && <ModalBoxFooter>{modalContent.code}</ModalBoxFooter>}
        </ModalBox>
      </Modal>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <PopoverBox>
          <InfoIcon />
          <Typography>{popoverContent}</Typography>
        </PopoverBox>
      </Popover>
    </>
  );
};

export default Page;
