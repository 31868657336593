import { callApi } from './api';

const LogLevel = ['fatal', 'error', 'warn', 'info', 'debug', 'trace'] as const;

export interface ClientException {
  level: typeof LogLevel[number];
  code?: string;
  message: string;
  sessionToken?: string;
  t: string;
}

const logError = async (exception: ClientException) => {
  try {
    await callApi(`/exceptions/`, 'POST', { data: exception });
  } catch (err: unknown) {
    console.error(err);
  }
};

export default logError;
